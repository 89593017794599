/* Base styles PARTE1*/
:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
}

/* Fix scrolling issues on iOS */
html {
  position: fixed;
  height: 100%;
  overflow: hidden;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  background-color: #f3f4f6;
  margin: 0;
  padding: 0;
  padding-top: var(--safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom, 0);
  min-height: -webkit-fill-available;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1a1a1a;
    color: #ffffff;
  }
}

/* Consente lo scroll solo all'interno dei contenitori designati */
.iframe-container {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll !important;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  background-color: white;
  overscroll-behavior-y: none !important; /* Fix per l'effetto rimbalzo */
}

@media (prefers-color-scheme: dark) {
  .iframe-container {
    background-color: #2d2d2d;
  }
}

/* Fix per evitare problemi di scroll su alcuni browser mobile */
iframe {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

/* Assicura che il contenuto non-iframe abbia scroll corretto */
main:not(.iframe-container) {
  height: calc(100vh - 88px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: calc(env(safe-area-inset-bottom) + 100px);
  overscroll-behavior-y: none !important; /* Fix per l'effetto rimbalzo */
}

.container {
  display: flex;
  flex-direction: column;
  min-height: auto;
  background-color: #f3f4f6;
  gap: 5px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 100px);
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none !important; /* Fix per l'effetto rimbalzo */
}

@media (prefers-color-scheme: dark) {
  .container {
    background-color: #1a1a1a;
  }
}
/* HERO SECTION PARTE2*/
.heroContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  position: relative; /* Aggiunto per il fix dello scroll */
  z-index: 1; /* Aggiunto per il fix dello scroll */
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .heroContainer {
    background-color: #2d2d2d;
  }
}

.welcomeText {
  font-size: 27px;
  font-weight: bold;
  color: #1e3a8a;
  margin-bottom: 24px;
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .welcomeText {
    color: #60a5fa;
  }
}

.heroImage {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 24px;
  object-fit: cover;
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  position: relative; /* Aggiunto per il fix dello scroll */
  z-index: 2; /* Aggiunto per il fix dello scroll */
}

.heroButton {
  background-color: #f1f5f9;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 31%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .heroButton {
    background-color: #374151;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }
}

.heroIcon {
  margin-bottom: 8px;
}

.heroText {
  font-size: 14px;
  font-weight: 600;
  color: #1e3a8a;
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .heroText {
    color: #60a5fa;
  }
}

.giftCardSection {
  margin-bottom: 10px;
  padding: 0 15px;
  margin-bottom: 10px;
  margin-top: -5px;
  height: calc(100vh - 88px) !important; /* Fix per lo scroll */
  overflow-y: auto !important; /* Fix per lo scroll */
  -webkit-overflow-scrolling: touch !important;
  overscroll-behavior-y: none !important; /* Fix per l'effetto rimbalzo */
  position: relative; /* Fix per lo scroll */
  z-index: 1;
}
/* Dialog/Modal styles PARTE3 */
.DialogContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background: white;
  border-radius: 12px;
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(0); /* Aggiunto translateZ per il fix su iOS */
  -webkit-transform: translate(-50%, -50%) translateZ(0);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  overscroll-behavior: contain; /* Fix per l'effetto rimbalzo nel modal */
  z-index: 100; /* Fix per il layering */
}

@media (prefers-color-scheme: dark) {
  .DialogContent {
    background: #2d2d2d;
    color: #ffffff;
  }
}

.DialogHeader {
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
}

.DialogFooter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  width: 100%;
}

.DialogFooter button {
  width: 100%;
}

.DialogTitle {
  font-size: 24px;
  font-weight: 600;
  color: #1e3a8a;
  margin-bottom: 8px;
}

@media (prefers-color-scheme: dark) {
  .DialogTitle {
    color: #60a5fa;
  }
}

.DialogDescription {
  color: #4b5563;
  line-height: 1.5;
}

@media (prefers-color-scheme: dark) {
  .DialogDescription {
    color: #9ca3af;
  }
}

/* Card styles */
.card {
  background-color: #fff;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.2s ease;
  position: relative; /* Fix per lo scroll */
  z-index: 2; /* Fix per lo scroll */
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}

.card:hover {
  transform: translateY(-2px);
}

@media (prefers-color-scheme: dark) {
  .card {
    background-color: #2d2d2d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.cardTitle {
  font-size: 16px;
  font-weight: 600;
  color: #1e3a8a;
  flex: 1;
  margin-right: 10px;
}

@media (prefers-color-scheme: dark) {
  .cardTitle {
    color: #60a5fa;
  }
}

.cardText {
  font-size: 14px;
  color: #4b5563;
  margin-bottom: 5px;
}

@media (prefers-color-scheme: dark) {
  .cardText {
    color: #9ca3af;
  }
}

.sectionTitle {
  font-size: 18px;
  padding: 12px 15px;
  font-weight: bold;
  color: #1e3a8a;
  text-align: left;
  margin-top: 0px !important;
  margin-bottom: 5px;
}

@media (prefers-color-scheme: dark) {
  .sectionTitle {
    color: #60a5fa;
  }
}
/* VIP section styles PARTE 4*/
.vipHeroContainer {
  background-color: #1e3a8a;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  position: relative; /* Fix per lo scroll */
  z-index: 1; /* Fix per lo scroll */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .vipHeroContainer {
    background-color: #1a1a1a;
  }
}

.vipHeroContainer.extended-blue-bg {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background-color: #1e3a8a;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .vipHeroContainer.extended-blue-bg {
    background-color: #1a1a1a;
  }
}

/* Services sections */
.ourServicesSection {
  padding: 0;
  background-color: #fff;
  margin-top: 0px;
  position: relative; /* Fix per lo scroll */
  z-index: 2; /* Fix per lo scroll */
}

@media (prefers-color-scheme: dark) {
  .ourServicesSection {
    background-color: #2d2d2d;
  }
}

.servicesRow {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 15px;
  gap: 8px;
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}

.surroundingButton, .serviceButton {
  flex: 1;
  height: 80px;
  background-color: #e0e7ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  min-width: 70px;
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .surroundingButton, .serviceButton {
    background-color: #374151;
  }
}

.surroundingText, .serviceText {
  font-size: 13px;
  color: #1e3a8a;
  font-weight: bold;
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .surroundingText, .serviceText {
    color: #60a5fa;
  }
}

/* Surroundings section */
.surroundingsSection {
  padding: 0;
  background-color: #fff;
  margin-top: 5px;
  position: relative; /* Fix per lo scroll */
  z-index: 2; /* Fix per lo scroll */
}

@media (prefers-color-scheme: dark) {
  .surroundingsSection {
    background-color: #2d2d2d;
  }
}

.surroundingsRow {
  display: flex;
  justify-content: space-between;
  padding: 0 15px 15px;
  gap: 8px;
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}
/* Modal styles PARTE 5*/
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  z-index: 99;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain; /* Fix per l'effetto rimbalzo */
}

.modalContent {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 100;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .modalContent {
    background-color: #2d2d2d;
  }
}

.modalTitle {
  font-size: 20px;
  font-weight: bold;
  color: #1e3a8a;
  margin-bottom: 15px;
}

@media (prefers-color-scheme: dark) {
  .modalTitle {
    color: #60a5fa;
  }
}

.modalText {
  font-size: 16px;
  color: #4b5563;
  text-align: center;
  margin-bottom: 15px;
}

@media (prefers-color-scheme: dark) {
  .modalText {
    color: #9ca3af;
  }
}

.modalButton {
  background-color: #25d366;
  padding: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}

.modalButtonText {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.closeButton {
  margin-top: 15px;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.closeButtonText {
  color: #6b7280;
}

@media (prefers-color-scheme: dark) {
  .closeButtonText {
    color: #9ca3af;
  }
}

/* VIP Text Styles */
.vipHeroTitle {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
}

.vipHeroSubtitle {
  font-size: 16px;
  color: #e0e7ff;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 10px;
}

.vipButton {
  background-color: #daa520;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
}

.vipButtonIcon {
  margin-right: 8px;
}

.vipButtonText {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

/* Delivery button styles */
.delivery-button {
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  height: 36px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  transition: background-color 0.2s;
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
  position: relative;
  z-index: 2;
}

.delivery-button:hover {
  background-color: #1d4ed8;
}

.golden-vip-button {
  background-color: #daa520 !important;
  color: white !important;
  border: none;
}
/* Utility classes PARTE 6*/
.spacer {
  height: 5px;
  background-color: #f3f4f6;
  position: relative; /* Fix per lo scroll */
  z-index: 1;
}

@media (prefers-color-scheme: dark) {
  .spacer {
    background-color: #1a1a1a;
  }
}

.subSectionTitle {
  color: #1e3a8a;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 10px;
}

@media (prefers-color-scheme: dark) {
  .subSectionTitle {
    color: #60a5fa;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-bottom: 24px;
  position: relative; /* Fix per lo scroll */
  z-index: 2;
}

.subTitleContainer {
  padding: 6px 12px;
  border-radius: 20px;
  background-color: #ecfdf5;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  margin-top: -2px;
  -webkit-transform: translateZ(0); /* Fix per animazioni su iOS */
  transform: translateZ(0);
}

@media (prefers-color-scheme: dark) {
  .subTitleContainer {
    background-color: #064e3b;
  }
}

.receptionText {
  font-size: 16px;
  font-weight: 600;
  color: #059669;
  text-align: center;
}

@media (prefers-color-scheme: dark) {
  .receptionText {
    color: #34d399;
  }
}

.discountBadge {
  background-color: #ecfdf5;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  -webkit-transform: translateZ(0); /* Fix per il rendering su iOS */
  transform: translateZ(0);
  position: relative; /* Aggiunto per l'effetto shimmer */
  overflow: hidden; /* Aggiunto per l'effetto shimmer */
}

@media (prefers-color-scheme: dark) {
  .discountBadge {
    background-color: #064e3b;
  }
}

.discountText {
  color: #059669;
  font-size: 12px;
  font-weight: 500;
}

@media (prefers-color-scheme: dark) {
  .discountText {
    color: #34d399;
  }
}

/* Nuovo stile shimmer aggiunto */
.shimmer-button {
  position: relative;
  overflow: hidden;
}

/* Modifica questa parte nel tuo CSS */
.shimmer-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.6), /* Aumentato da 0.3 a 0.6 per il tema chiaro */
    transparent
  );
  animation: shimmer 3s infinite;
}

/* Aggiungi questa media query per il tema scuro */
@media (prefers-color-scheme: dark) {
  .shimmer-button::after {
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
  }
}

@keyframes shimmer {
  0% { left: -100% }
  100% { left: 200% }
}

.glowing {
  animation: glowing 2s ease-in-out infinite;
}

/* Animations - con fix per performance */
@keyframes pulse {
  0%, 100% {
    opacity: 1;
    transform: translateZ(0) scale(1);
  }
  50% {
    opacity: 0.9;
    transform: translateZ(0) scale(1.05);
  }
}

@keyframes glowing {
  0% {
    background-color: #ecfdf5;
    box-shadow: 0 0 5px rgba(5, 150, 105, 0.2);
    transform: translateZ(0);
  }
  50% {
    background-color: #d1fae5;
    box-shadow: 0 0 20px rgba(5, 150, 105, 0.4);
    transform: translateZ(0);
  }
  100% {
    background-color: #ecfdf5;
    box-shadow: 0 0 5px rgba(5, 150, 105, 0.2);
    transform: translateZ(0);
  }
}

@media (prefers-color-scheme: dark) {
  @keyframes glowing {
    0% {
      background-color: #064e3b;
      box-shadow: 0 0 5px rgba(52, 211, 153, 0.2);
      transform: translateZ(0);
    }
    50% {
      background-color: #065f46;
      box-shadow: 0 0 20px rgba(52, 211, 153, 0.4);
      transform: translateZ(0);
    }
    100% {
      background-color: #064e3b;
      box-shadow: 0 0 5px rgba(52, 211, 153, 0.2);
      transform: translateZ(0);
    }
  }
}

/* Media queries e platform-specific fixes */
@media (orientation: landscape) {
  .DialogContent {
    max-height: 90vh;
    max-width: 800px;
    width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overscroll-behavior: contain; /* Fix per l'effetto rimbalzo */
  }

  .DialogHeader {
    width: 100%;
    max-width: 600px;
    text-align: center;
  }

  .DialogDescription {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }

  .DialogContent > div {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .DialogContent .grid-cols-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: 100%;
    max-width: 400px;
    margin: 8px auto;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .container {
    overflow-x: hidden;
    padding-bottom: calc(env(safe-area-inset-bottom) + 120px);
  }
  
  main:not(.iframe-container) {
    padding-bottom: calc(env(safe-area-inset-bottom) + 120px);
  }

  .DialogContent {
    max-height: 80vh;
    padding: 20px;
  }

  .grid {
    gap: 0.5rem;
  }

  .grid > * {
    min-height: 4rem;
  }

  .delivery-button {
    height: 36px !important;
    max-height: 36px !important;
    min-height: 36px !important;
    padding: 0 12px !important;
    font-size: 14px !important;
    -webkit-appearance: none;
  }

  .modalContent {
    max-height: 80vh;
    overflow-y: auto;
  }

  .servicesRow, .surroundingsRow {
    padding-bottom: 20px;
  }

  .vipHeroContainer {
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
  }
}

@media all and (display-mode: standalone) {
  body {
    padding-top: env(safe-area-inset-top);
  }

  .modalOverlay {
    padding-top: env(safe-area-inset-top);
  }

  .vipHeroContainer.extended-blue-bg {
    padding-top: env(safe-area-inset-top);
  }

  /* Fix aggiuntivo per lo scroll su iPhone in modalità standalone */
  .giftCardSection {
    height: calc(100vh - 88px - env(safe-area-inset-bottom)) !important;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
    overscroll-behavior-y: none !important;
  }
}




