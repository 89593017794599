/* FloatingChatButton.css */
.floating-chat-button {
  position: fixed;
  bottom: 120px;
  right: 20px;
  z-index: 9999;
  pointer-events: auto;
}

.chat-button {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #1e3a8a;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.chat-prompt {
  position: absolute;
  right: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: max-content;
  max-width: 200px;
  font-size: 14px;
  word-wrap: break-word;
  z-index: 9998;
  animation: fadeIn 0.3s ease-in-out;
}

@media screen and (max-width: 350px) {
  .chat-prompt {
    max-width: 150px;
    right: calc(100% + 8px);
    font-size: 13px;
  }
}

.chat-prompt::after {
  content: '';
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 8px solid white;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.chat-prompt-close {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #1e3a8a;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50%) translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(-50%) translateX(0);
  }
}

/* Fix specifico per iPhone */
@supports (-webkit-touch-callout: none) {
  .floating-chat-button {
    bottom: calc(120px + env(safe-area-inset-bottom, 0px));
    right: 20px;
    z-index: 99999;
  }

  .ios-device {
    transform: translateZ(0);
    -webkit-tap-highlight-color: transparent;
  }

  .ios-device .chat-button {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

/* Specifica per la versione standalone su iOS */
@media all and (display-mode: standalone) {
  @supports (-webkit-touch-callout: none) {
    .floating-chat-button {
      bottom: calc(70px + env(safe-area-inset-bottom, 0px));
    }
  }
}

/* Media query per schermi più piccoli o in modalità landscape */
@media screen and (max-height: 700px) {
  .floating-chat-button {
    bottom: 100px;
  }
  
  @supports (-webkit-touch-callout: none) {
    .floating-chat-button {
      bottom: calc(100px + env(safe-area-inset-bottom, 0px));
    }
  }
}

@media (prefers-color-scheme: dark) {
  .chat-prompt {
    background-color: #1e293b;
    color: white;
  }

  .chat-prompt::after {
    border-left-color: #1e293b;
  }
}
